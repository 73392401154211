import React from "react";
import { NoData } from "../../../GobalStyled";
import { AdvanceTablesWidget4 } from "../../../_metronic/_partials/widgets";

export default function UserChallenge({ data }) {
  return (
    <>
      {data?.length > 0 ? (
        <AdvanceTablesWidget4 data={data} />
      ) : (
        <NoData>NO DATA FOUND</NoData>
      )}
    </>
  );
}
