import React from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";

export default function DeleteBlock({
  open,
  close,
  deleteAction,
  confirmSumbit,
  parameter,
  loading,
}) {
  const actionConfirm = () => {
    confirmSumbit(deleteAction);
  };
  const style = {
    position: "absolute",
    right: "-20px",
    top: "-20px",
    width: "50px",
    height: "50px",
    background: "red",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
    fontSize: "28px",
    zIndex: "1",
    cursor: "pointer",
  };
  return (
    <Modal
      show={open}
      onHide={close}
      dialogClassName="modal-120w"
      centered
      style={{ background: "rgba(0,0,0,0.6)" }}
      aria-labelledby="example-custom-modal-styling-title"
    >
      <span style={style} onClick={close}>
        &#10008;
      </span>
      <Modal.Body>
        <ModalMessage>
          {parameter.name === "Approve"
            ? "Do you want to approve this request?"
            : `Are you sure, you want to ${parameter.name} ${parameter.body}?`}
        </ModalMessage>

        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button variant="primary" onClick={actionConfirm}>
            {loading ? <div className="SpinnerStyle" /> : "Yes ! Go ahead"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const ModalMessage = styled.div`
  font-size: 22px;
  text-align: center;
  padding: 10px 0;
`;
