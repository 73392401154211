/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import {
  UpdateChallenge,
  ViewChallenge,
  challengeImageDelete,
  challengeRulesListing,
} from "../Auth/_redux/authCrud";
import { toast } from "react-toastify";
import { ImageContainer } from "../../../GobalStyled";

export default function EditChallenge(props) {
  const [loading, setloading] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [data, setData] = useState(null);
  const history = useHistory();
  const [pic, setPic] = useState([]);
  const [challengeImg, setChallengeImg] = useState([]);
  const [rulesData, setRulesData] = useState([]);
  const initialValues = {
    title: "",
    startDate: "",
    challengePeriod: "",
    endDate: "",
    challengeAmount: "",
    depositeAmount: "",
    category: "",
    totalHours: "",
    hoursPerDay: "",
    description: "",
    pic: [],
    image_length: 0,
    rules: "",
  };

  useEffect(() => {
    if (data && formik) {
      let timeHours = "";
      let taskData = "";
      if (data.category === "time") {
        timeHours = data.no_of_hours;
      } else {
        taskData = data.no_of_hours;
      }
      formik.setValues({
        pic: [],
        description: data.description,
        title: data.title,
        startDate: data.start_date,
        challengePeriod: data.challenge_period,
        endDate: data.end_date,
        challengeAmount: data.challenge_amount,
        category: data.category,
        hoursPerDay: timeHours,
        totalHours: taskData,
        rules: data?.rule_id,
        assigned_task: data?.assigned_task !== null ? data?.assigned_task : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const Schema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .min(5, "Title must contain atleast 5 charachers"),
    startDate: Yup.date().required("Start Date is required"),
    challengePeriod: Yup.string(),
    endDate: Yup.date()
      .when("startDate", (startDate, schema) => {
        return (
          startDate &&
          schema.min(
            startDate,
            "You can't select the previous date then start date "
          )
        );
      })
      .required("End Date is required"),
    description: Yup.string().required("description is mandatory"),
    challengeAmount: Yup.string().required("Challenge Amount is required"),

    category: Yup.string().required(),
    image_length: Yup.number()
      .min(2, " Please add min 2")
      .max(5, "Max 5 images"),
    hoursPerDay: Yup.number().when("category", {
      is: "time",
      then: Yup.number()
        .required("Hours is a required Field")
        .min(1, "Hours can't be less than 1")
        .max(24, "Hours can't be greater than 24 hours"),
    }),
    assigned_task: Yup.string().when("category", {
      is: "task",
      then: Yup.string()
        .required("Assigned Task is a required Field")
        .min(10)
        .max(100),
    }),
    // totalHours: Yup.number()
    //   .when("category", {
    //     is: "task",
    //     then: Yup.number().required("Please enter the number of hours"),
    //   })
    //   .when("challengePeriod", (days, schema) => {
    //     // let min = days * 24 - days * 23;
    //     let max = days * 24 - days * 14;
    //     return schema
    //       .min(1, `Total Hours must be greater than or equal to 1`)
    //       .max(max, `Total Hours must be less than or equal to ${max}`);
    //   }),
    pic: Yup.array().max(5 - challengeImg.length),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const Chal = (start, end) => {
    if (start && end) {
      let starting = new Date(start);
      starting.setDate(starting.getDate() - 1);
      let difference = new Date(end).getTime() - starting.getTime();

      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
      return TotalDays;
    }
    return "";
  };
  // const hours = () => {
  //   if (values.category === "task") {
  //     return values.totalHours;
  //   } else {
  //     return values.hoursPerDay;
  //   }
  // };
  const submit = async (values) => {
    let formData = new FormData();
    setloading(true);

    formData.append("rule_id", values.rules);
    formData.append("title", values.title);
    formData.append("start_date", values.startDate);
    formData.append("end_date", values.endDate);
    formData.append("challenge_amount", values.challengeAmount);
    formData.append("no_of_hours", values.hoursPerDay);
    formData.append("description", values.description);
    if (values.category === "task") {
      formData.append("assigned_task", values.assigned_task);
    }

    if (values.pic.length > 0) {
      // eslint-disable-next-line array-callback-return
      values.pic?.map((val) => {
        formData.append("image_name", val);
      });
    }
    formData.append("challenge_id", data.id);
    let res = await UpdateChallenge(formData);
    if (res.status === 200) {
      setloading(false);
      history.push("/challenges");
      toast.success(res.message, { theme: "colored" });
    } else {
      setloading(false);
      toast.error(res.message, { theme: "colored" });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: submit,
  });
  const getRules = async () => {
    let res = await challengeRulesListing();
    if (res.status === 200) {
      setRulesData(res.data);
      // console.log(res.data, "rules---------");
      // formik.setFieldValue("rules", data);
    }
  };
  const getDetailsOfChallenge = async () => {
    setPageLoader(true);

    let res = await ViewChallenge(props.location.state.id);
    if (res.status === 200) {
      // console.log(res);
      setData(res.data);
      setChallengeImg(res.data.challengeImages);

      setPageLoader(false);
    } else {
      toast.error(res.message, { theme: "colored" });
      setPageLoader(false);
    }
  };
  const handleRules = (name) => {
    // eslint-disable-next-line array-callback-return
    rulesData.map((value) => {
      if (value.title === name) {
        formik.setFieldValue("rules", value.id);
      }
    });
  };
  useEffect(() => {
    getDetailsOfChallenge();
    getRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ImageCard = (data, type) => {
    let res = data.map((value, id) => {
      let img, ind;
      if (type === "apiData") {
        img = value.image_url;
        ind = value.id;
      } else {
        img = URL.createObjectURL(value);
        ind = id;
      }
      return (
        <div>
          <div className="mx-1 rounded">
            <img src={img} alt="" />
            <span onClick={() => removeImg(ind, type)}>✘</span>
          </div>
        </div>
      );
    });
    return res;
  };

  useEffect(() => {
    formik.setFieldTouched("image_length", true);
    formik.values.image_length = pic.length + challengeImg.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pic.length, challengeImg.length]);

  const handleImages = (event) => {
    let requireLen = 5 - challengeImg.length;
    if (pic.length === 0) {
      const files = event.target.files;
      let myFiles = Array.from(files);
      if (myFiles.length <= requireLen) {
        setPic(myFiles);
        formik.setFieldValue("pic", myFiles);
      }
    } else {
      if (pic.length < requireLen) {
        const files = event.target.files;
        let update = pic.concat(Array.from(files));
        if (update.length <= requireLen) {
          setPic(update);
          formik.setFieldValue("pic", update);
        }
      }
    }
  };
  const removeImg = async (id, type) => {
    if (type === "apiData") {
      setloading(true);
      let res = await challengeImageDelete(id);
      if (res.status === 200) {
        let update = [];
        // eslint-disable-next-line array-callback-return
        challengeImg.map((val) => {
          if (val.id !== id) {
            update.push(val);
          }
        });
        setChallengeImg(update);
        // console.log("success");
        toast.success(res.message, { theme: "colored" });
        setloading(false);
      } else {
        toast.error(res.message, { theme: "colored" });
        setPageLoader(false);
      }
    } else {
      let update = [];
      // eslint-disable-next-line array-callback-return
      pic.map((val, ind) => {
        if (ind !== id) {
          update.push(val);
        }
      });
      setPic(update);
    }
  };

  if (pageLoader) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }

  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark mt-4">
            Update Existing Challenge
          </h3>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            Save Changes
            {formik.isSubmitting}
          </button>
          <Link to="/challenges" className="btn btn-secondary">
            Cancel
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        <div className="card-body">
          {/* begin::Heading */}
          <div className="row">
            <label className="col-xl-3"></label>
          </div>
          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Images</label>
            <div className="col-lg-9 col-xl-6">
              <label
                className="btn  btn-xs btn-icon  btn-white    btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Addpic"
              >
                <i className="fa fa-clone icon-lg  mx-1 "></i>
                <input
                  name="pic"
                  type="file"
                  className={`border-0 ${getInputClasses("pic")}`}
                  accept="image/*"
                  multiple
                  onChange={(event) => {
                    handleImages(event);
                  }}
                  onBlur={(e) => formik.handleBlur(e)}
                />
                <input type="hidden" name="pic" />
              </label>
              <span className="text-muted pl-5 pb-4">
                {" "}
                Add min 2 and max 5 images{" "}
              </span>
              {pic.length + challengeImg.length < 2 &&
              formik.errors.image_length ? (
                <div className="text-danger">{formik.errors.image_length}</div>
              ) : null}

              <ImageContainer>
                {challengeImg && ImageCard(challengeImg, "apiData")}
                {pic?.length > 0 && ImageCard(pic, "files")}
              </ImageContainer>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Challenge Name
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "title"
                  )}`}
                  name="title"
                  {...formik.getFieldProps("title")}
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.title}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Start Date
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="date"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "startDate"
                  )}`}
                  name="startDate"
                  {...formik.getFieldProps("startDate")}
                />
                {formik.touched.startDate && formik.errors.startDate ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.startDate}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {formik.values.startDate && (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                End Date
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="date"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "endDate"
                    )}`}
                    name="endDate"
                    {...formik.getFieldProps("endDate")}
                  />
                  {formik.touched.endDate && formik.errors.endDate ? (
                    <div className="invalid-feedback ml-2">
                      {formik.errors.endDate}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Challenge Period
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "challengePeriod"
                  )}`}
                  readOnly
                  // value={Chal(formik.values.startDate, formik.values.endDate)}
                  name="challengePeriod"
                  {...(formik.values.challengePeriod = Chal(
                    formik.values.startDate,
                    formik.values.endDate
                  ))}
                  {...formik.getFieldProps("challengePeriod")}
                />

                {formik.touched.challengePeriod &&
                formik.errors.challengePeriod ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.challengePeriod}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Challenge Amount
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="number"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "challengeAmount"
                  )}`}
                  name="challengeAmount"
                  {...formik.getFieldProps("challengeAmount")}
                />

                {formik.touched.challengeAmount &&
                formik.errors.challengeAmount ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.challengeAmount}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Category</label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="form-control form-control-lg form-control-solid custom-control-input"
                    id="time"
                    name="category"
                    value="time"
                    checked={formik.values.category === "time"}
                    onChange={(e) =>
                      formik.setFieldValue("category", e.target.value)
                    }
                    onBlur={(e) => formik.handleBlur(e)}
                  />
                  <label class="custom-control-label" for="time">
                    Time Based
                  </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input green"
                    id="task"
                    name="category"
                    value="task"
                    checked={formik.values.category === "task"}
                    onChange={(e) =>
                      formik.setFieldValue("category", e.target.value)
                    }
                    onBlur={(e) => formik.handleBlur(e)}
                  />
                  <label class="custom-control-label" for="task">
                    Task Based
                  </label>
                </div>

                {formik.touched.category && formik.errors.category ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.category}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {formik.values.category === "time" && (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Hours per day
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="number"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "hoursPerDay"
                    )}`}
                    name="hoursPerDay"
                    {...formik.getFieldProps("hoursPerDay")}
                  />
                  {formik.touched.hoursPerDay && formik.errors.hoursPerDay ? (
                    <div className="invalid-feedback ml-2">
                      {formik.errors.hoursPerDay}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          {formik.values.category === "task" && (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Assigned task
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "assigned_task"
                    )}`}
                    name="assigned_task"
                    {...formik.getFieldProps("assigned_task")}
                  />
                  {formik.touched.assigned_task &&
                  formik.errors.assigned_task ? (
                    <div className="invalid-feedback ml-2">
                      {formik.errors.assigned_task}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          {(formik.values.rules !== "" || undefined) && rulesData.length > 0 ? (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Challenge Rules
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <select
                    name="rules"
                    className={`form-control form-control-lg form-control-solid `}
                    onChange={(e) => handleRules(e.target.value)}
                  >
                    {rulesData?.map((item) => {
                      if (item?.id === formik.values.rules) {
                        return <option selected> {item.title}</option>;
                      }
                      return <option> {item.title}</option>;
                    })}
                  </select>
                  {formik.touched.rules && formik.errors.rules ? (
                    <div className="invalid-feedback ml-2">
                      {formik.errors.rules}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Description
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <textarea
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "description"
                  )}`}
                  name="description"
                  {...formik.getFieldProps("description")}
                />
                {formik.touched.description && formik.errors.description ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.description}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Email Address
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className="input-group input-group-lg input-group-solid">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-at"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="Email"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "email"
                    )}`}
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="invalid-feedback">{formik.errors.email}</div>
                  ) : null}
                </div>
                <span className="form-text text-muted">
                  Email will not be publicly displayed.{` `}
                  <a href="#" className="font-weight-bold">
                    Learn more
                  </a>
                  .
                </span>
              </div>
            </div> */}
          {/* <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Deposit Amount
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="number"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "depositeAmount"
                  )}`}
                  name="depositeAmount"
                  {...formik.getFieldProps("depositeAmount")}
                />

                {formik.touched.depositeAmount &&
                formik.errors.depositeAmount ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.depositeAmount}
                  </div>
                ) : null}
              </div>
            </div>
          </div> */}
          {/* <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Days per week
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="number"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "daysPerWeek"
                  )}`}
                  name="daysPerWeek"
                  {...formik.getFieldProps("daysPerWeek")}
                />
                {formik.touched.daysPerWeek && formik.errors.daysPerWeek ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.daysPerWeek}
                  </div>
                ) : null}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </form>
  );
}
