/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ListingProfilePictures } from "../../../GobalStyled";

import {
  updateWithdrawlRequest,
  widdrawlRequestListing,
} from "../Auth/_redux/authCrud";
import DeleteBlock from "../Modals/DeleteBlock";
import DeclineInfo from "./components/DeclineInfo";
import ViewBankDetail from "./components/ViewBankDetails";

export default function WithdrawListing() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [openDeny, setOpenDeny] = useState(false);
  // const [page, setPage] = useState(1);
  const [modalLoading, setModalLoading] = useState(false);
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const [parameter, setParameter] = useState({
    request_id: "",
    status: "",
    reason: "",
  });

  const sortData = (data) => {
    let sort = data.sort((a, b) => {
      return a.status - b.status;
    });
    setData(sort);
  };

  const getRequestListing = async () => {
    setLoading(true);
    //filter to list all reports as per the actions taken on them by admin
    let res = await widdrawlRequestListing();
    if (res.status === 200) {
      console.log(res.data, "resssssssss--------");
      sortData(res.data);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error();
    }
  };
  const Approve = (payload) => {
    setOpenActiveDeactive(true);
    let para = {
      name: "Approve",
      body: "this Request",
      id: payload,
      status: "completed",
    };
    setParameter(para);
  };
  const Deny = (payload) => {
    setOpenDeny(true);
    let para = {
      name: "Deny",
      body: "this Request",
      id: payload,
      status: "cancel",
      reason: "",
    };
    setParameter(para);
  };

  const ActiveDeactive = async () => {
    setModalLoading(true);
    const req = {
      request_id: parameter.id,
      status: parameter.status,
      reason: parameter.reason,
    };
    console.log(req);
    let res = await updateWithdrawlRequest(req);
    if (res.status === 200) {
      setModalLoading(false);
      setOpenDeny(false);
      setOpenActiveDeactive(false);
      toast.info(res.message);
      getRequestListing();
    } else {
      setOpenDeny(false);
      setModalLoading(false);
      setOpenActiveDeactive(false);
      toast.info(res.message);
    }
  };

  useEffect(() => {
    getRequestListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }

  return (
    <div className={`card card-custom `}>
      {/* Head */}
      {/* <div className="card-header border-0 p-0"></div> */}
      {/* Body */}
      <div className="card-body pt-0 pb-3 my-4">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th
                    className="pl-2 text-center"
                    style={{ minWidth: "100px" }}
                  >
                    User
                  </th>
                  <th style={{ minWidth: "100px" }}>Username</th>
                  <th style={{ minWidth: "100px" }}>Status</th>
                  <th style={{ minWidth: "40px" }}>Amount $</th>
                  <th style={{ minWidth: "250px", textAlign: "center" }}>
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item) => (
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-1 pl-5">
                          <span className="symbol-label">
                            {item?.bankDetail?.user.profile_pic ? (
                              <ListingProfilePictures
                                src={item?.bankDetail?.user.profile_pic}
                                alt="Profile"
                              />
                            ) : item?.user.gender === "male" ? (
                              <ListingProfilePictures
                                src="../media/svg/avatars/001-boy.svg"
                                alt="Profile"
                              />
                            ) : (
                              <ListingProfilePictures
                                src="../media/svg/avatars/002-girl.svg"
                                alt="Profile"
                              />
                            )}
                          </span>
                        </div>
                        <div className="mx-2">
                          <a
                            href
                            className="text-dark-75 font-weight-bolder text-hover-primary mb-1 pl-2 font-size-lg "
                            // onClick={() => openChallenge(item)}
                          >
                            {item?.bankDetail?.user.first_name}{" "}
                            {item?.bankDetail?.user.last_name}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg pl-5">
                        {item?.bankDetail?.user.user_name}
                      </span>
                    </td>
                    <td>
                      <span className="d-flex flex-row">
                        {item?.status === "pending" ? (
                          <span className="text-warning w-100 font-weight-bolder">
                            Pending
                          </span>
                        ) : item?.status === "completed" ? (
                          <span className="text-success w-100 font-weight-bolder ">
                            Completed
                          </span>
                        ) : item?.status === "inprogress" ? (
                          <span className="text-info w-100 font-weight-bolder ">
                            In-Progress{" "}
                          </span>
                        ) : item?.status === "cancel" ? (
                          <span className="text-danger w-100 font-weight-bolder ">
                            Canceled{" "}
                          </span>
                        ) : null}

                        {/* {item?.reason !== null && item?.reason !== "" ? (
                          <div
                            className="d-flex"
                            style={{
                              // flexDirection: "column !important",
                              width: "200px",
                            }}
                          >
                            <span className="font-weight-bolder w-50">
                              Reason:
                            </span>
                            <p className="text-muted " style={{ width: "50%" }}>
                              {" "}
                              {item?.reason}
                            </p>
                          </div>
                        ) : null} */}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder font-size-lg pl-5">
                        {item?.amount}
                      </span>
                    </td>
                    <td className="pr-0 text-center">
                      <button
                        className={`btn btn-light-info font-weight-bolder font-size-sm mx-1`}
                        onClick={() => {
                          setShow(true);
                          setModalData(item);
                        }}
                      >
                        View
                      </button>

                      <button
                        disabled={
                          item?.status === "completed" ||
                          item?.status === "cancel"
                        }
                        className="btn btn-light-danger font-weight-bolder font-size-sm mx-1"
                        onClick={() => Deny(item?.id)}
                      >
                        Deny
                      </button>

                      <button
                        disabled={
                          item?.status === "completed" ||
                          item?.status === "cancel"
                        }
                        className="btn btn-light-success font-weight-bolder font-size-sm mx-1"
                        onClick={() => Approve(item?.id)}
                      >
                        Approve
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {openActiveDeactive && (
        <DeleteBlock
          open={openActiveDeactive}
          close={() => setOpenActiveDeactive(false)}
          confirmSumbit={ActiveDeactive}
          parameter={parameter}
          loading={modalLoading}
        />
      )}
      {openDeny && (
        <DeclineInfo
          open={openDeny}
          close={() => setOpenDeny(false)}
          confirmSumbit={ActiveDeactive}
          parameter={parameter}
          // setVal={setReasonVal}
          loading={modalLoading}
        />
      )}

      {show && (
        <ViewBankDetail
          open={show}
          data={modalData}
          close={() => setShow(false)}
        />
      )}
      {/* <PageChanger>
        {page > 1 && (
          <span
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            Go Back
          </span>
        )}
        {data.length > 8 && (
          <span className="active" onClick={() => setPage(page + 1)}>
            See More
          </span>
        )}
      </PageChanger> */}
    </div>
  );
}
