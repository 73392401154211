import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  ActivateDeactivateChallenge,
  AllChallengeListing,
  DeleteChallenge,
} from "../Auth/_redux/authCrud";
import { toast } from "react-toastify";
import DeleteBlock from "../Modals/DeleteBlock";
import ChallengeView from "./ChallengeView";
import { useHistory } from "react-router";

export default function Challenge() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const [parameter, setParameter] = useState({});
  const [show, setShow] = useState(false);
  const [challengeID, setChallengeID] = useState("");
  const history = useHistory();

  const getAllChallengeListing = async () => {
    setLoading(true);
    let res = await AllChallengeListing();
    if (res.status === 200) {
      setData(res.data);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error();
    }
  };

  const ActiveDeactiveActions = (payload) => {
    setOpenActiveDeactive(true);
    if (payload.is_active) {
      let para = {
        name: "Deactivate",
        body: "this challenge",
        status: false,
        id: payload.id,
      };
      setParameter(para);
    } else {
      let para = {
        name: "Activate",
        body: "this challenge",
        status: true,
        id: payload.id,
      };
      setParameter(para);
    }
  };

  const Delete = (payload) => {
    setOpenActiveDeactive(true);
    let para = {
      name: "Delete",
      body: "this challenge",
      id: payload.id,
    };
    setParameter(para);
  };

  const ActiveDeactive = async () => {
    setModalLoading(true);
    if (parameter.name === "Activate" || parameter.name === "Deactivate") {
      let req = {
        challenge_id: parameter.id,
        status: parameter.status,
      };
      let res = await ActivateDeactivateChallenge(req);
      if (res.status === 200) {
        setModalLoading(false);
        setOpenActiveDeactive(false);
        toast.info(res.message);
        getAllChallengeListing();
      } else {
        setModalLoading(false);
        setOpenActiveDeactive(false);
        toast.info(res.message);
      }
    }
    if (parameter.name === "Delete") {
      let res = await DeleteChallenge(parameter.id);
      if (res.status === 200) {
        setModalLoading(false);
        setOpenActiveDeactive(false);
        toast.info(res.message);
        getAllChallengeListing();
      } else {
        setModalLoading(false);
        setOpenActiveDeactive(false);
        toast.info(res.message);
      }
    }
  };

  //Open Challenge View Screen

  const openChallenge = (id) => {
    setChallengeID(id);
    setShow(true);
  };

  useEffect(() => {
    getAllChallengeListing();
  }, []);

  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }

  return (
    <div className={`card card-custom `}>
      {/* Head */}

      {/* Body */}
      <div className="card-body pt-0 pb-3 my-4">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-2" style={{ minWidth: "250px" }}>
                    <span className="mx-1">Challenge Name</span>
                  </th>
                  <th style={{ minWidth: "90px" }}>start date</th>
                  {/* <th style={{minWidth: "100px"}}></th> */}
                  <th style={{ minWidth: "90px" }}>end date</th>
                  <th style={{ minWidth: "100px" }}>status</th>
                  <th style={{ minWidth: "130px", padding: "0 50px" }}>
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="mx-2">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <a
                            className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg "
                            // onClick={() => openChallenge(item)}
                          >
                            {item.title}
                          </a>
                          <span className="text-muted font-weight-bold d-block">
                            Challenge Amount: ${item.challenge_amount}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.start_date}
                      </span>
                      {/* <span className="text-muted font-weight-bold">
                        In Proccess
                      </span> */}
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.end_date}
                      </span>
                      {/* <span className="text-muted font-weight-bold">
                        Web, UI/UX Design
                      </span> */}
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item?.status === "completed" ? (
                          <span className="text-warning">Completed</span>
                        ) : item?.status === "new" ? (
                          <span className="text-info">Upcoming</span>
                        ) : (
                          <span style={{ color: "#20b839d1" }}>Started</span>
                        )}
                      </span>
                      {/* 
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        {item.status}
                      </span> */}
                    </td>
                    <td className="pr-0 text-right">
                      <button
                        className="btn btn-light-warning font-weight-bolder font-size-sm mx-1"
                        onClick={() => ActiveDeactiveActions(item)}
                        disabled={item?.status === "completed"}
                      >
                        {item.is_active === true ? "Deactivate" : "Activate"}
                      </button>

                      <button
                        className="btn btn-light-success font-weight-bolder font-size-sm mx-1"
                        onClick={() => openChallenge(item.id)}
                      >
                        View
                      </button>

                      <button
                        className="btn btn-light-info font-weight-bolder font-size-sm mx-1"
                        onClick={() =>
                          history.push("/Edit-challenge", {
                            id: item.id,
                          })
                        }
                        disabled={item?.status === "completed"}
                      >
                        Edit
                      </button>

                      <button
                        className="btn btn-light-danger font-weight-bolder font-size-sm mx-1"
                        onClick={() => Delete(item)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {openActiveDeactive && (
        <DeleteBlock
          open={openActiveDeactive}
          close={() => setOpenActiveDeactive(false)}
          confirmSumbit={ActiveDeactive}
          parameter={parameter}
          loading={modalLoading}
        />
      )}

      {show && (
        <ChallengeView
          open={show}
          id={challengeID}
          close={() => setShow(false)}
        />
      )}
    </div>
  );
}
