import React from "react";
import {
  InfoLabel,
  InfoLine,
  InfoValue,
  InfoWrapper,
} from "../../../GobalStyled";

export default function UserInfo({ data }) {
  return (
    <InfoWrapper>
      <div>
        <InfoLine>
          <InfoLabel>First Name</InfoLabel>
          <InfoLabel>Last Name</InfoLabel>
        </InfoLine>
        <InfoLine>
          <InfoValue>{data?.first_name}</InfoValue>
          <InfoValue>{data?.last_name}</InfoValue>
        </InfoLine>
        <InfoLine>
          <InfoLabel>User Name</InfoLabel>
          <InfoLabel>Phone Number</InfoLabel>
        </InfoLine>
        <InfoLine>
          <InfoValue>@{data?.user_name}</InfoValue>
          <InfoValue>
            {data?.country_code} {data?.mobile_number}
          </InfoValue>
        </InfoLine>
        <InfoLine>
          <InfoLabel>Email</InfoLabel>
          <InfoLabel>verified with</InfoLabel>
        </InfoLine>
        <InfoLine>
          <InfoValue>{data?.email}</InfoValue>
          <InfoValue>
            {data?.email_verified && "Email"}
            {data?.email_verified && data?.number_verified && " & "}
            {data?.number_verified && "Phone Number"}
          </InfoValue>
        </InfoLine>

        <InfoLine>
          <InfoLabel>DOB</InfoLabel>
          <InfoLabel>Gender</InfoLabel>
        </InfoLine>
        <InfoLine>
          <InfoValue>{data?.birth_date}</InfoValue>
          <InfoValue>{data?.gender}</InfoValue>
        </InfoLine>
        <InfoLine>
          <InfoLabel>Base City</InfoLabel>
          <InfoLabel>Base Country</InfoLabel>
        </InfoLine>
        <InfoLine>
          <InfoValue>Delhi</InfoValue>
          <InfoValue>India</InfoValue>
        </InfoLine>

        <InfoLine>
          <InfoLabel>Study Goals</InfoLabel>
          <InfoLabel>Study Hours</InfoLabel>
        </InfoLine>
        <InfoLine>
          <InfoValue>{data?.study_goals}</InfoValue>
          <InfoValue>{data?.study_hours}</InfoValue>
        </InfoLine>
      </div>
      {/* <Line /> */}
      {/* <div>
        <InfoLine>
          <InfoLabel>Types of Activities</InfoLabel>
        </InfoLine>
        {data?.selectedActivities?.map((ele) => (
          <InfoLine>
            <InfoValue>{ele?.activityName}</InfoValue>
          </InfoLine>
        ))}

        <InfoLine>
          <InfoLabel>Types of Travel</InfoLabel>
        </InfoLine>
        {data?.selectedTravels?.map((ele) => (
          <InfoLine>
            <InfoValue>{ele?.travelName}</InfoValue>
          </InfoLine>
        ))}
      </div> */}
    </InfoWrapper>
  );
}
