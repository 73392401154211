/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import ViewChallenges from "../../../../app/modules/UserProfile/ViewChallenges";

export function AdvanceTablesWidget4({ className, data }) {
  const [show, setShow] = useState(false);
  const [challengeData, setChallengeData] = useState("");

  const openChallenge = (payload) => {
    setChallengeData(payload);

    setShow(true);
  };

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}

      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-2" style={{ minWidth: "250px" }}>
                    <span className="text-dark-75">title</span>
                  </th>
                  <th style={{ minWidth: "100px" }}>start date</th>
                  {/* <th style={{minWidth: "100px"}}></th> */}
                  <th style={{ minWidth: "100px" }}>end date</th>
                  <th style={{ minWidth: "130px" }}>status</th>
                  <th style={{ minWidth: "80px" }} />
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="mx-2">
                          <a
                            className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg "
                            onClick={() => openChallenge(item.challenge_id)}
                          >
                            {item.challenge.title}
                          </a>
                          <span className="text-muted font-weight-bold d-block">
                            HTML, JS, ReactJS
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.challenge.start_date}
                      </span>
                      <span className="text-muted font-weight-bold">
                        In Proccess
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.challenge.end_date}
                      </span>
                      <span className="text-muted font-weight-bold">
                        Web, UI/UX Design
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.challenge.status}
                      </span>

                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        {item.challenge.status}
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <button
                        className="btn btn-light-success font-weight-bolder font-size-sm"
                        onClick={() => openChallenge(item.challenge.id)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {show && (
            <ViewChallenges
              open={show}
              id={challengeData}
              close={() => setShow(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
