import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  InfoLabel,
  InfoLine,
  InfoValue,
  InfoWrapper,
} from "../../../GobalStyled";
import { ViewChallenge } from "../Auth/_redux/authCrud";

const style = {
  position: "absolute",
  right: "-20px",
  top: "-20px",
  width: "50px",
  height: "50px",
  background: "red",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#ffffff",
  fontSize: "28px",
  zIndex: "1",
  cursor: "pointer",
};

export default function ChallengeView({ open, close, id }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const getChallengeDetails = async () => {
    setLoading(true);
    let res = await ViewChallenge(id);
    if (res.status === 200) {
      setLoading(false);
      setData(res.data);
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
      close();
    }
  };

  useEffect(() => {
    getChallengeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      show={open}
      onHide={close}
      dialogClassName="modal-120w"
      size="md"
      centered
      style={{ background: "rgba(0,0,0,0.6)" }}
    >
      <span style={style} onClick={close}>
        &#10008;
      </span>
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          View Challenge
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="LoaderWrapper">
            <div className="BackgroundLoader" />
          </div>
        ) : (
          <InfoWrapper>
            <div>
              <InfoLine>
                <InfoLabel>Start Date</InfoLabel>
                <InfoLabel>End Date</InfoLabel>
              </InfoLine>
              <InfoLine>
                <InfoValue>{data?.start_date}</InfoValue>
                <InfoValue>{data?.end_date}</InfoValue>
              </InfoLine>

              <InfoLine>{/* <InfoLabel>Hours per day</InfoLabel> */}</InfoLine>
              <InfoLine>
                {/* <InfoValue>{data?.no_of_hours}</InfoValue> */}
              </InfoLine>

              <InfoLine>
                <InfoLabel>Challenge Amount</InfoLabel>
                <InfoLabel>Challenge Period</InfoLabel>
              </InfoLine>
              <InfoLine>
                <InfoValue>${data?.challenge_amount}</InfoValue>
                <InfoValue>{data?.challenge_period} Days</InfoValue>
              </InfoLine>
              <InfoLine>
                <InfoLabel>Category</InfoLabel>

                <InfoLabel>
                {data?.category === "task"
                    ? "Assigned Task"
                    : " Hours Per Day"}
                </InfoLabel>

                {/* <InfoLabel>Current Week</InfoLabel> */}
              </InfoLine>
              <InfoLine>
                <InfoValue>{data?.category} based</InfoValue>

                <InfoValue> {data?.category === "task"
                    ? data?.assigned_task
                    : data?.no_of_hours}</InfoValue>

                {/* <InfoValue>{data?.current_week}</InfoValue> */}
              </InfoLine>

              <InfoLine>
                <InfoLabel>Status</InfoLabel>
                {/* <InfoLabel>Current Day</InfoLabel> */}
              </InfoLine>
              <InfoLine>
                <InfoValue>{data?.status}</InfoValue>
                {/* <InfoValue>{data?.current_day}</InfoValue> */}
              </InfoLine>

              <InfoLine>
                <InfoLabel>Images</InfoLabel>
              </InfoLine>
              <InfoLine>
                <InfoValue>
                  <div>
                    {data &&
                      data?.challengeImages?.map((val) => (
                        <img src={val.image_url} alt={val.image_url} />
                      ))}
                  </div>
                </InfoValue>
              </InfoLine>
            </div>
          </InfoWrapper>
        )}
      </Modal.Body>
    </Modal>
  );
}
