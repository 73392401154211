import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { ListingProfilePictures, PageChanger } from "../../../GobalStyled";

import { ReportListing } from "../Auth/_redux/authCrud";

export default function Report() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(3);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState("All");
  const history = useHistory();

  const sortData = (data) => {
    let sort = data.sort((a, b) => {
      return a.status - b.status;
    });
    setData(sort);
  };
  const getbtnClasses = (status) => {
    if (status === 3) {
      return "danger";
    } else if (status === 2) {
      return "dark";
    }
    return "success";
  };

  const getAllReportListing = async () => {
    setLoading(true);
    //filter to list all reports as per the actions taken on them by admin
    let res = await ReportListing(filter, page);
    if (res.status === 200) {
      console.log(res.data, "resssssssss--------");
      sortData(res.data);

      setLoading(false);
    } else {
      setLoading(false);
      toast.error();
    }
  };
  const handleDropdown = (value, toggledata) => {
    setPage(1);
    setFilter(value);
    setToggle(toggledata);
  };
  const getToggleClass = () => {
    if (toggle === "Approved") {
      return "danger";
    } else {
      if (toggle === "Denied") {
        return "dark";
      } else {
        return "success";
      }
    }
  };
  useEffect(() => {
    getAllReportListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page]);

  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }

  return (
    <div className={`card card-custom `}>
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <Dropdown>
          <Dropdown.Toggle variant={getToggleClass()} id="dropdown-basic">
            {toggle}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleDropdown(3, "All")}>
              All
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDropdown(2, "Approved")}>
              Approved
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDropdown(1, "Denied")}>
              Denied
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3 my-4">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-2" style={{ minWidth: "100px" }}></th>
                  <th className="pl-2" style={{ minWidth: "100px" }}>
                    Reported By
                  </th>
                  <th style={{ minWidth: "100px" }}>Username</th>
                  <th style={{ minWidth: "250px" }}>Reason</th>
                  <th style={{ minWidth: "40px" }}>Count</th>
                  <th style={{ minWidth: "100px" }}>action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item) => (
                  <tr>
                    <td className="pl-0 py-4">
                      <div className="symbol symbol-50 symbol-light mr-1">
                        <span className="symbol-label">
                          {item?.user.profile_pic ? (
                            <ListingProfilePictures
                              src={item?.user.profile_pic}
                              alt="Profile"
                            />
                          ) : item?.user.gender === "male" ? (
                            <ListingProfilePictures
                              src="../media/svg/avatars/001-boy.svg"
                              alt="Profile"
                            />
                          ) : (
                            <ListingProfilePictures
                              src="../media/svg/avatars/002-girl.svg"
                              alt="Profile"
                            />
                          )}
                        </span>
                      </div>
                    </td>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="mx-2">
                          <a
                            href
                            className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg "
                            // onClick={() => openChallenge(item)}
                          >
                            {item?.user.first_name} {item?.user.last_name}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item?.user.user_name}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item?.reason}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item?.count}
                      </span>
                    </td>
                    <td className="pr-0 text-left">
                      <button
                        className={`btn btn-light-${getbtnClasses(
                          item.status
                        )} font-weight-bolder font-size-sm mx-1`}
                        onClick={() =>
                          history.push("/view-report", {
                            id: item.feed_id,
                            count: item.count,
                            reason: item.reason,
                          })
                        }
                      >
                        View
                      </button>

                      {/* <button
                        className="btn btn-light-info font-weight-bolder font-size-sm mx-1"
                        // onClick={() =>
                        //   history.push("/challenge/Editchallenge", {
                        //     id: item.id,
                        //   })
                        // }
                      >
                        Edit
                      </button> 
                      <button
                        className="btn btn-light-danger font-weight-bolder font-size-sm mx-1"
                        // onClick={() => Delete(item)}
                      >
                        Delete
                      </button> 
                        <button
                        className="btn btn-light-warning font-weight-bolder font-size-sm mx-1"
                        // onClick={() => ActiveDeactiveActions(item)}
                      >
                        {item?.is_active === true ? "Deactivate" : "Activate"}
                      </button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* {openActiveDeactive && (
        <DeleteBlock
          open={openActiveDeactive}
          close={() => setOpenActiveDeactive(false)}
          confirmSumbit={ActiveDeactive}
          parameter={parameter}
          loading={modalLoading}
        />
      )}

      {show && (
        <ChallengeView
          open={show}
          id={challengeID}
          close={() => setShow(false)}
        />
      )} */}
      <PageChanger></PageChanger>
      <PageChanger>
        {page > 1 && (
          <span
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            Go Back
          </span>
        )}
        {data.length > 8 && (
          <span className="active" onClick={() => setPage(page + 1)}>
            See More
          </span>
        )}
      </PageChanger>
    </div>
  );
}
