/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import { useHistory } from "react-router-dom";
// import  getBreadcrumbsAndTitle, useSubheader, "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import styled from "styled-components";
import { AddButton } from "../../../../GobalStyled";
import * as moment from "moment";
import { useState } from "react";

export function SubHeader() {
  const uiService = useHtmlClassService();
  const todayDate = new Date();
  const history = useHistory();
  const [headerName, setHeaderName] = useState("");

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)

  const getHeaderName = () => {
    //.replace(/[^a-zA-Z ]/g, " ")
    let HeaderName = history.location.pathname;
    console.log(HeaderName);

    //comments

    // if (HeaderName === "/user-profile/account-information") {
    //   setHeaderName("account information");
    // }
    // if (HeaderName === "/user-profile/change-password") {
    //   setHeaderName("change password");
    // }

    if (
      HeaderName.includes("profile-overview") ||
      HeaderName.includes("account-information") ||
      HeaderName.includes("change-password")
    ) {
      if (HeaderName.includes("profile-overview")) {
        setHeaderName("user profiles");
      } else {
        setHeaderName("account settings");
      }
    } else {
      setHeaderName(HeaderName.replace(/[^a-zA-Z ]/g, " "));
    }
  };

  useEffect(() => {
    getHeaderName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          <BackIcon onClick={() => history.goBack()}>&#60;</BackIcon>
          <div className="d-flex align-items-baseline mr-5">
            <h5 className="text-dark font-weight-bold my-2 mr-5">
              <SubHeaderName>{headerName}</SubHeaderName>
            </h5>
          </div>
        </div>

        {/* Toolbar */}
        <div className="d-flex align-items-center">
          <a
            className="btn btn-light btn-sm font-weight-bold"
            data-toggle="tooltip"
            title="Select dashboard daterange"
            data-placement="left"
          >
            <span
              className="text-muted font-weight-bold mr-2"
              id="kt_dashboard_daterangepicker_title"
            >
              Today,
            </span>
            <span
              className="text-primary font-weight-bold"
              id="kt_dashboard_daterangepicker_date"
            >
              {moment(todayDate).format("DD MMM YYYY")}
            </span>
          </a>

          <div className="p-2">
            {history.location.pathname === "/challenges" && (
              <AddButton onClick={() => history.push("/Add-challenge")}>
                Add Challenge
              </AddButton>
            )}
            {history.location.pathname === "/rules" && (
              <AddButton onClick={() => history.push("/add-chalenge-rule")}>
                Add Challenge Rule
              </AddButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const SubHeaderName = styled.div`
  text-transform: uppercase;
`;

const BackIcon = styled.span`
  cursor: pointer;
  font-size: 25px;
  padding: 0 10px 0 0;
  transition: font-size;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;

  :hover {
    font-size: 35px;
  }
`;
