import React, { useState } from "react";
import { useEffect } from "react";
// import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
  ActionButtonForUser,
  DetailsRow,
  PageInnerContainer,
  UserFollowingDetails,
  UserInfoBox,
  UserProfileInfo,
  UserProfileInfoInner,
} from "../../../GobalStyled";
import { getErrorMessage } from "../../../utils/functions";
import { viewReport, actionOnFeed } from "../Auth/_redux/authCrud";
import DeleteBlock from "../Modals/DeleteBlock";
import ReportedByInfo from "./components/ReportedByInfo";
import ReportInfo from "./components/ReportInfo";

export default function ViewReport(props) {
  const [activeTab, setActiveTab] = useState("info");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [parameter, setParameter] = useState({});
  const [modalLoder, setModalLoder] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [reportedBy, setReportedBy] = useState([]);
  const [status, setStatus] = useState(1);
  const [rpCount, setRpCount] = useState(0);

  // const history = useHistory();

  const addData = (data) => {
    setUserData(data.user);
    setReportedBy(data.reports);
    setRpCount(data.reports.length);
    setStatus(data.reports[0].status);
    setReportData({ report: data, reason: props.location.state.reason });
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getDetails = async () => {
    enableLoading();

    const res = await viewReport(props.location.state.id);
    if (res.status === 200) {
      addData(res.data);

      disableLoading();
    } else {
      const message = getErrorMessage(res, "Not Found");
      toast.error(message, { theme: "colored" });
      disableLoading();
    }
  };
  // note regarding functions Approve and denyReport
  // return status false to delete the reported feed and return  status true to deny and prevent the feed from getting deleted.
  const ApproveReport = (id) => {
    if (status !== 3) {
      setOpenModal(true);
      let para = {
        name: "Approve",
        body: "this Report",
        id: id,
        status: false,
      };
      setParameter(para);
    }
  };

  const denyReport = (id) => {
    if (status !== 2) {
      setOpenModal(true);
      let para = {
        name: "Deny",
        body: "this Report",
        id: id,
        status: true,
      };
      setParameter(para);
    }
  };

  const Action = async () => {
    setModalLoder(true);
    let req = {
      feed_id: parameter.id,
      status: parameter.status,
    };

    let res = await actionOnFeed(req);
    if (res.status === 200) {
      setModalLoder(false);
      setOpenModal(false);

      toast.success(res.message, { theme: "colored" });
    } else {
      setModalLoder(false);
      setOpenModal(false);
      toast.error(res.message, { theme: "colored" });
    }
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }

  return (
    <>
      {openModal && (
        <DeleteBlock
          open={openModal}
          close={() => setOpenModal(false)}
          confirmSumbit={Action}
          parameter={parameter}
          loading={modalLoder}
        />
      )}

      {userData ? (
        <PageInnerContainer>
          <UserInfoBox>
            <UserProfileInfo>
              {userData.profile_pic ? (
                <img src={userData.profile_pic} alt="Profile" />
              ) : userData.gender === "male" ? (
                <img src="../media/svg/avatars/001-boy.svg" alt="Profile" />
              ) : (
                <img src="../media/svg/avatars/002-girl.svg" alt="Profile" />
              )}
              <div>
                <h4>{userData?.first_name + " " + userData?.last_name}</h4>
                  <span>@{userData?.user_name}</span>
                <UserProfileInfoInner>
                  <p>Student</p>
                  <label>
                    {userData?.active === true ? "Active" : "Inactive"}
                  </label>
                </UserProfileInfoInner>
              </div>
            </UserProfileInfo>
            <UserFollowingDetails>
              <div>
                <p>{rpCount}</p>
                <span>Number of Reports</span>
              </div>
            </UserFollowingDetails>
            <ActionButtonForUser>
              {(status === 3 || status === 1) && (
                <span
                  className="Delete"
                  onClick={() => ApproveReport(props.location.state.id)}
                >
                  {status === 3 ? "Action Taken Approved" : "Approve"}
                </span>
              )}
              {(status === 2 || status === 1) && (
                <span
                  className="Block"
                  onClick={() => denyReport(props.location.state.id)}
                >
                  {status === 2 ? "Action Taken Denied" : "Deny"}
                </span>
              )}
            </ActionButtonForUser>
            </UserInfoBox>
          <DetailsRow tab={activeTab === "info" ? 1 : 2}>
            <div>
              <span
                className={activeTab === "info" && "active"}
                onClick={() => setActiveTab("info")}
              >
                Report Info
              </span>
            </div>
            <div>
              <span
                className={activeTab === "video" && "active"}
                onClick={() => setActiveTab("video")}
              >
                Reported By
              </span>
            </div>
          </DetailsRow>
          {activeTab === "info" && <ReportInfo data={reportData} />}
          {activeTab === "video" && <ReportedByInfo data={reportedBy} />}
        </PageInnerContainer>
      ) : (
        <span className="ml-3 spinner spinner-white"></span>
      )}
    </>
  );
}
