import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import { InfoLabel, InfoLine, InfoValue } from "../../../../GobalStyled";

export default function DeclineInfo({
  open,
  close,
  deleteAction,
  confirmSumbit,
  parameter,
  loading,
}) {
  const actionConfirm = () => {
    confirmSumbit(deleteAction);
  };
  const [decline, setDecline] = useState(true);
  const style = {
    position: "absolute",
    right: "-20px",
    top: "-20px",
    width: "50px",
    height: "50px",
    background: "red",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
    fontSize: "28px",
    zIndex: "1",
    cursor: "pointer",
  };

  return (
    <Modal
      show={open}
      onHide={close}
      dialogClassName="modal-120w"
      centered
      style={{ background: "rgba(0,0,0,0.6)" }}
      aria-labelledby="example-custom-modal-styling-title"
    >
      <span style={style} onClick={close}>
        &#10008;
      </span>
      <Modal.Body>
        <ModalMessage>Please enter the reason for denial.</ModalMessage>
        <InfoLine>
          <InfoValue className="text-right">
            <Input
              name="reason"
              maxLength={50}
              type="text"
              onChange={(e) => {
                parameter.reason = e.target.value;
                if (e.target.value.length > 0) {
                  setDecline(false);
                } else {
                  setDecline(true);
                }
              }}
            />
          </InfoValue>
          <InfoLabel>
            <div className="mx-auto">
              <Button
                variant="primary"
                onClick={actionConfirm}
                disabled={decline}
              >
                {loading ? <div className="SpinnerStyle" /> : "Submit"}
              </Button>
            </div>
          </InfoLabel>
        </InfoLine>
      </Modal.Body>
    </Modal>
  );
}

const ModalMessage = styled.div`
  font-size: 22px;
  text-align: center;
  padding: 10px 0;
`;
const Input = styled.textarea`
  font-size: 14px;
  width: 80%;
  border-radius: 5px;
  border: 1px solid grey;
  outline: none;
`;
