import React from "react";
import {
  InfoLabel,
  InfoLine,
  InfoValue,
  InfoWrapper,
  ListingProfilePictures,
} from "../../../../GobalStyled";
const ReportedByInfo = (props) => {
  //console.log(props);

  const data = props.data;
  return (
    <InfoWrapper>
      <div>
        <InfoLine>
          <InfoLabel>User</InfoLabel>
          <InfoLabel>UserName</InfoLabel>
        </InfoLine>
        {data?.map((item) => (
          <InfoLine>
            <InfoValue>
              <span className="symbol-label w ">
                <ListingProfilePictures
                  src={item?.user.profile_pic}
                  alt="Profile"
                />
              </span>
              <span className="mx-4 text-hover-primary cursor-pointer">
                {item?.user.first_name} {item?.user.last_name}
              </span>
            </InfoValue>
            <InfoValue>
              <span className="text-success cursor-pointer">
                @{item?.user.user_name}
              </span>
            </InfoValue>
          </InfoLine>
        ))}
      </div>
    </InfoWrapper>
  );
};
export default ReportedByInfo;
