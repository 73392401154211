import { useFormik } from "formik";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { CreateChallengeRule } from "../Auth/_redux/authCrud";
const AddChallengeRules = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    title: "",
    rules: "",
  };
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };
  const Schema = Yup.object().shape({
    title: Yup.string()
      .min(2)
      .max(20)
      .required("Title is required"),
    rules: Yup.string().required("Rules must be defined"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
      setLoading(true);
      let req = {
        title: values.title,
        rules: values.rules,
      };

      let res = await CreateChallengeRule(req);
      if (res.status === 200) {
        setLoading(false);
        toast.success(res.message);
        history.push("/rules");
      } else {
        toast.error(res.message);
        setLoading(false);
      }
    },
  });

  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Add a new Rule
          </h3>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            Save Changes
            {formik.isSubmitting}
          </button>
          <Link to="/rules" className="btn btn-secondary">
            Cancel
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        <div className="card-body">
          {/* begin::Heading */}
          <div className="row">
            <label className="col-xl-3"></label>
          </div>
          {/* begin::Form Group */}

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Title</label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "title"
                  )}`}
                  name="title"
                  {...formik.getFieldProps("title")}
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.title}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Challenge Rules
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <textarea
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "rules"
                  )}`}
                  name="rules"
                  {...formik.getFieldProps("rules")}
                />

                {formik.touched.rules && formik.errors.rules ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.rules}
                  </div>
                    ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export default AddChallengeRules;
