/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { InfoLabel, InfoLine, InfoWrapper } from "../../../../GobalStyled";

const style = {
  position: "absolute",
  right: "-20px",
  top: "-20px",
  width: "50px",
  height: "50px",
  background: "red",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#ffffff",
  fontSize: "28px",
  zIndex: "1",
  cursor: "pointer",
};

export default function ViewBankDetail({ open, close, data }) {
  const bankDetail = data?.bankDetail;
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      show={open}
      onHide={close}
      dialogClassName="modal-120w"
      size="md"
      centered
      style={{ background: "rgba(0,0,0,0.6)" }}
    >
      <span style={style} onClick={close}>
        &#10008;
      </span>
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Account Details & Status
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="LoaderWrapper">
            <div className="BackgroundLoader" />
          </div>
        ) : (
          <InfoWrapper>
            <div>
              <InfoLine>
                <InfoLabel>Account Holder</InfoLabel>
                <InfoLabel style={{ color: "black", fontWeight: "500" }}>
                  {bankDetail?.accountHolder}
                </InfoLabel>
              </InfoLine>
              <InfoLine>
                <InfoLabel>Bank Name</InfoLabel>
                <InfoLabel style={{ color: "black", fontWeight: "500" }}>
                  {bankDetail?.bankName}
                </InfoLabel>
              </InfoLine>
              <InfoLine>
                <InfoLabel>Account Number</InfoLabel>
                <InfoLabel style={{ color: "black", fontWeight: "500" }}>
                  {bankDetail?.accountNumber}
                </InfoLabel>
              </InfoLine>
              <InfoLine>
                <InfoLabel>Routing Number</InfoLabel>
                <InfoLabel style={{ color: "black", fontWeight: "500" }}>
                  {bankDetail?.ifsc_code}
                </InfoLabel>
              </InfoLine>
              <InfoLine>
                <InfoLabel>Status</InfoLabel>
                <InfoLabel style={{ color: "black", fontWeight: "500" }}>
                  {data?.status === "pending" ? (
                    <span className="text-warning w-100 font-weight-bolder">
                      Pending
                    </span>
                  ) : data?.status === "completed" ? (
                    <span className="text-success w-100 font-weight-bolder ">
                      Completed
                    </span>
                  ) : data?.status === "inprogress" ? (
                    <span className="text-info w-100 font-weight-bolder ">
                      In-Progress{" "}
                    </span>
                  ) : data?.status === "cancel" ? (
                    <span className="text-danger w-100 font-weight-bolder ">
                      Canceled{" "}
                    </span>
                  ) : null}
                </InfoLabel>
              </InfoLine>{" "}
              {data?.reason !== null && data?.status === "cancel" ? (
                <InfoLine>
                  <InfoLabel className="">Reason</InfoLabel>
                  <InfoLabel
                    style={{
                      // width: "150px",
                      color: "black",
                      fontWeight: "500",
                      wordBreak:
                        data?.reason.split(" ").length === 1
                          ? "break-all"
                          : "normal",
                    }}
                  >
                    {data?.reason}
                  </InfoLabel>
                </InfoLine>
              ) : null}
            </div>
          </InfoWrapper>
        )}
      </Modal.Body>
    </Modal>
  );
}
