import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import ViewProfile from "./modules/UserProfile/ViewProfile";
import AccountInformation from "./modules/UserProfile/AccountInformation";
import Challenge from "./modules/Challenge/Challenge";
import AddChallenge from "./modules/Challenge/AddChallenge";
import EditChallenge from "./modules/Challenge/EditChallenge";
import Report from "./modules/Report/Report";
import WithdrawListing from "./modules/Payments/WithdrawListing";

import ViewReport from "./modules/Report/ViewReport";
import Rules from "./modules/AppVariable/ChallengeRules";
import AddChallengeRules from "./modules/AppVariable/AddChallengeRules";
import EditChallengeRules from "./modules/AppVariable/EditChallengeRules";
const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/user-details" component={ViewProfile} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/Add-challenge" component={AddChallenge} />
        <Route path="/Edit-challenge" component={EditChallenge} />
        <Route path="/challenges" component={Challenge} />
        <Route path="/reports" component={Report} />
        <Route path="/withdraw_requests" component={WithdrawListing} />
        <Route path="/view-report" component={ViewReport} />
        <Route path="/rules" component={Rules} />
        <Route path="/add-chalenge-rule" component={AddChallengeRules} />
        <Route path="/edit-chalenge-rule" component={EditChallengeRules} />

        <Route path="/AccountInformation" component={AccountInformation} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
