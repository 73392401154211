/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
  searchUser,
  userDelete,
  userListing,
} from "../../../../app/modules/Auth/_redux/authCrud";
import DeleteBlock from "../../../../app/modules/Modals/DeleteBlock";
import { ListingProfilePictures, NoData } from "../../../../GobalStyled";

export function AdvanceTablesWidget2({ className }) {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);

  const [openDelete, setOpenDelete] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [parameter, setParameter] = useState({});

  const history = useHistory();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getSearchWord = async (word) => {
    let s = word.target.value;

    if (s) {
      await searchUser(word.target.value)
        .then(({ data }) => {
          // console.log(data, "data--------------");
          setUserData(data);
        })
        .catch(() => {
          disableLoading();
        });
    }
    if (!s) {
      setUserData([]);
      getUserListing();
    }
  };

  const getUserListing = () => {
    enableLoading();
    userListing()
      .then(({ data }) => {
        if (data !== undefined) {
          setUserData(data);
        }
        // console.log(data);
        disableLoading();
        // dispatch(loginAdmin(data))
      })
      .catch(() => {
        disableLoading();
      });
  };

  // const deleteAction = (id) => {
  //   enableLoading();
  //   let parameter = {
  //     name: "Delete",
  //     id: id,
  //   };
  //   userBlockDeleteAction(parameter)
  //     .then(({ data }) => {
  //       console.log(data);
  //       getUserListing();
  //       disableLoading();
  //     })
  //     .catch((error) => {
  //       disableLoading();
  //     });
  // };

  const Delete = (payload) => {
    setOpenDelete(true);
    let para = {
      name: "Delete",
      body: "this user",
      id: payload,
    };
    setParameter(para);
  };

  const ActiveDeactive = async () => {
    setModalLoading(true);

    let res = await userDelete(parameter.id);
    if (res.status === 200) {
      setModalLoading(false);
      setOpenDelete(false);
      toast.info(res.message);
      getUserListing();
    } else {
      setModalLoading(false);
      setOpenDelete(false);
      toast.info(res.message);
    }
  };

  useEffect(() => {
    getUserListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }

  return (
    <div className={`card card-custom ${className}`}>
      {openDelete && (
        <DeleteBlock
          open={openDelete}
          close={() => setOpenDelete(false)}
          confirmSumbit={ActiveDeactive}
          parameter={parameter}
          loading={modalLoading}
        />
      )}

      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            New Arrivals
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            {userData?.length > 10
              ? `More than ${Math.floor(userData?.length / 10) * 10} new users!`
              : `Total Users : ${userData.length}`}
          </span>
        </h3>
        <input
          name="search"
          type="search"
          placeholder="Search"
          onChange={getSearchWord}
          className="form-control w-25  ml-auto mt-5"
        />
      </div>
      {userData?.length > 0 ? (
        userData.map((ele) => (
          <div className="card-body pt-3 pb-0">
            <div className="table-responsive">
              <table className="table table-borderless table-vertical-center">
                <thead>
                  <tr>
                    <th className="p-0" style={{ width: "60px" }} />
                    <th className="p-0" style={{ minWidth: "250px" }} />
                    <th className="p-0" style={{ minWidth: "100px" }} />
                    {/* <th className="p-0" style={{ minWidth: "125px" }} /> */}
                    {/* <th className="p-0" style={{ minWidth: "110px" }} /> */}
                    <th className="p-0" style={{ minWidth: "150px" }} />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="pl-0 py-4">
                      <div className="symbol symbol-50 symbol-light mr-1">
                        <span className="symbol-label">
                          {ele.profile_pic ? (
                            <ListingProfilePictures
                              src={ele.profile_pic}
                              alt="Profile"
                            />
                          ) : ele.gender === "male" ? (
                            <ListingProfilePictures
                              src="../media/svg/avatars/001-boy.svg"
                              alt="Profile"
                            />
                          ) : (
                            <ListingProfilePictures
                              src="../media/svg/avatars/002-girl.svg"
                              alt="Profile"
                            />
                          )}
                        </span>
                      </div>
                    </td>
                    <td className="pl-0">
                      <span
                        onClick={() =>
                          history.push("/user-details", { id: ele.id })
                        }
                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg cursor-pointer"
                      >
                        {ele.first_name + " " + ele.last_name}
                      </span>
                      <div>
                        <span className="font-weight-bolder">Email: </span>
                        <a
                          className="text-muted font-weight-bold text-hover-primary"
                          href="#"
                        >
                          {ele.email}
                        </a>
                      </div>
                    </td>

                    <td className="text-right">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {ele.mobile_number}
                      </span>

                      <span className="text-muted font-weight-bold">
                        <span className="label label-lg label-light-primary label-inline my-1 cursor-pointer">
                          {ele.user_name}
                        </span>
                      </span>
                    </td>
                    {/* <td className="text-right">
                  <span className="text-muted font-weight-500">
                   {ele.user_name}
                  </span>
                </td> */}

                    <td className="text-right pr-0">
                      <button
                        className="btn btn-light-success font-weight-bolder font-size-sm mx-1"
                        onClick={() =>
                          history.push("/user-details", { id: ele.id })
                        }
                      >
                        View
                      </button>

                      {ele?.deleted === false ? (
                        <button
                          className="btn btn-light-danger  font-weight-bolder font-size-sm mx-1"
                          onClick={() => Delete(ele.id)}
                        >
                          Delete
                        </button>
                      ) : (
                        <button className="btn btn-light-info btn-sm font-weight-bolder font-size-sm mx-1">
                          Deleted
                        </button>
                      )}

                      {/* <a
                        className="btn btn-icon btn-light btn-sm"
                        onClick={() =>
                          history.push("/viewProfile", { id: ele.id })
                        }
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Visible.svg"
                            )}
                          ></SVG>
                        </span>
                      </a>
      
                      <a
                        className="btn btn-icon btn-light btn-sm mx-3"
                        onClick={() => deleteAction(ele.id)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                          ></SVG>
                        </span>
                      </a> */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ))
      ) : (
        <NoData>DATA NOT FOUND</NoData>
      )}
    </div>
  );
}
