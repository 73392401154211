import axios from "axios";
import { deleteApi, getApi, postApi } from "../../../Services/ApiMethod";
export const REGISTER_URL = "api/auth/register";

export const LOGIN_URL = "admin-login";
export const FORGOT = "forget-password";
export const CHANGE_PASSWORD = "change-password";
export const UPDATE_ADMINPROFILE = "edit-admin-profile";
export const UserList = "users-list";
export const SEARCH_USER = "search-user";
export const UserDetails = "view-profile";
export const USER_DELETE = "delete-user";
export const USER_BLOCK_UNBLOCK = "block-unblock-user";
export const BLOCK_DELETE = "block-delete-user";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ADD_CHALLENGE = "create-challenge";
export const EDIT_CHALLENGE = "edit-challenge";
export const CHALLENGE_LISTING = "challenge-listing";
export const ACTIVATEDEACTIVATE_CHALLENGE = "activate-deactivate-challenge";
export const DELETE_CHALLENGE = "delete-challenge";
export const VIEW_CHALLENGE = "view-challenge";
export const VIEW_REPORT_FEED = "view-reported-feed";
export const VIEW_REPORT = "view-reported-feed-detail";
export const ACTION_ON_FEED = "action-on-feed";
export const DELETE_CHALLENGE_IMAGE = "delete-challenge-image";
export const CHALLENGE_RULES_LISTING = "view-challenge-rule";
export const ADD_CHALLENGE_RULE = "add-challenge-rule";
export const EDIT_CHALLENGE_RULE = "edit-challenge-rule";
export const VIEW_WITHDRAWL_REQUEST = "view-withdraw-request";
export const UPDATE_WITHDRAWL_REQUEST = "update-request-status";
export const ME_URL = "api/me";

export function login(email, password) {
  return postApi(LOGIN_URL, { email, password });
}

export function forgotpassword(email) {
  return postApi(FORGOT, { email });
}

export function changePassword(currentPassword) {
  return postApi(CHANGE_PASSWORD, currentPassword);
}

export function updateAdminProfile(values) {
  return postApi(UPDATE_ADMINPROFILE, values);
}

export function userListing() {
  return getApi(UserList);
}

export function userDelete(id) {
  return deleteApi(`${USER_DELETE}/${id}`);
}

export function blockUnblockUser(values) {
  return postApi(USER_BLOCK_UNBLOCK, values);
}

export function userDetails(userID) {
  return getApi(`${UserDetails}/${userID}`);
}

export function searchUser(value) {
  return getApi(`${SEARCH_USER}/${value}`);
}

export function CreateChallenge(values) {
  return postApi(ADD_CHALLENGE, values);
}

export function UpdateChallenge(values) {
  return postApi(EDIT_CHALLENGE, values);
}
export function challengeImageDelete(id) {
  return deleteApi(`${DELETE_CHALLENGE_IMAGE}/${id}`);
}

export function AllChallengeListing() {
  return getApi(CHALLENGE_LISTING);
}

export function ActivateDeactivateChallenge(values) {
  return postApi(ACTIVATEDEACTIVATE_CHALLENGE, values);
}

export function DeleteChallenge(id) {
  return deleteApi(`${DELETE_CHALLENGE}/${id}`);
}

export function ViewChallenge(id) {
  return getApi(`${VIEW_CHALLENGE}/${id}`);
}

export function ReportListing(id, page) {
  return getApi(`${VIEW_REPORT_FEED}?filter=${id}&page=${page}`);
}
export function viewReport(id) {
  return getApi(`${VIEW_REPORT}/${id}`);
}
export function actionOnFeed(values) {
  return postApi(ACTION_ON_FEED, values);
}
export function challengeRulesListing() {
  return getApi(CHALLENGE_RULES_LISTING);
}
export function CreateChallengeRule(values) {
  return postApi(ADD_CHALLENGE_RULE, values);
}
export function UpdateChallengeRules(values) {
  return postApi(EDIT_CHALLENGE_RULE, values);
}
export function widdrawlRequestListing() {
  return getApi(VIEW_WITHDRAWL_REQUEST);
}
export function updateWithdrawlRequest(value) {
  return postApi(UPDATE_WITHDRAWL_REQUEST, value);
}
export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

// export function requestPassword(email) {
//   return axios.post(REQUEST_PASSWORD_URL, { email });
// }

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
