import { LOGIN_SUCCESS, LOGOUT } from "./actionTypes";

const INIT_STATE = {
  firstname: null,
  lastname: null,
  email: null,
  token: null,
};

export default function Admin(state = INIT_STATE, action) {

  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        firstname: action.payload.first_name,
        lastname: action.payload.last_name,
        email: action.payload.email,
        token: action.payload.token,
      };
    }
    case LOGOUT:
      return INIT_STATE;
    default:
      return state;
  }
}
