import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { challengeRulesListing } from "../Auth/_redux/authCrud";
import ViewChallengeRules from "./ViewChallengeRules";
import DeleteBlock from "../Modals/DeleteBlock";
import { useHistory } from "react-router";
const Rules = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalLoder, setModalLoder] = useState(false);
  const [show, setShow] = useState(false);
  const [ruleId, setRuleId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [parameter, setParameter] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [rulesData, setRulesData] = useState({});
  const history = useHistory();
  const getChallengeRules = async () => {
    setLoading(true);
    let res = await challengeRulesListing();
    if (res.status === 200) {
      // toast.success(res.message);
      // console.log(res);

      setData(res?.data);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(res.message);
    }
  };
  const getStatusClass = (status) => {
    // console.log(status);
    if (status) {
      return "danger";
    }
    return "success";
  };
  // const deleteRule = (id) => {
  //   setOpenModal(true);
  //   let para = {
  //     name: "Delete",
  //     body: "this",
  //     id: id,
  //     status: true,
  //   };
  //   setParameter(para);
  // };
  const openChallenge = (id, data) => {
    setRuleId(id);
    setRulesData(data);
    setShow(true);
  };

  const Action = async () => {
    setModalLoder(true);
    // let req = {
    //   feed_id: parameter.id,
    //   status: parameter.status,
    // };

    // let res = await actionOnFeed(req);
    // if (res.status === 200) {
    //   setModalLoder(false);
    //   setOpenModal(false);

    //   toast.success(res.message, { theme: "colored" });
    // } else {
    //   setModalLoder(false);
    //   setOpenModal(false);
    //   toast.error(res.message, { theme: "colored" });
    // }
  };

  useEffect(() => {
    getChallengeRules();
  }, []);

  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }
  return (
    <>
      {openModal && (
        <DeleteBlock
          open={openModal}
          close={() => setOpenModal(false)}
          confirmSumbit={Action}
          parameter={parameter}
          loading={modalLoder}
        />
      )}

      {show && (
        <ViewChallengeRules
          open={show}
          id={ruleId}
          close={() => setShow(false)}
          ruleData={rulesData}
        />
      )}
      <div className={`card card-custom`}>
        <div className="card-body pt-0 pb-3 my-4">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                  <tr className="text-left text-uppercase">
                    <th className="pl-2" style={{ minWidth: "100px" }}>
                      Title
                    </th>
                    <th style={{ minWidth: "100px" }}>Status</th>
                    <th style={{ minWidth: "100px" }}>action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item) => (
                    <tr>
                      <td className="pl-0 py-8">
                        <div className="d-flex align-items-center">
                          <div className="mx-2">
                            <a
                              href
                              className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg "
                              // onClick={() => openChallenge(item)}
                            >
                              {item.title}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className="pl-0 py-8">
                        <div className="d-flex align-items-center">
                          <div className="mx-2">
                            <a
                              href
                              className={`text-${getStatusClass(
                                item.active
                              )}-75 font-weight-bolder  mb-1 font-size-lg`}
                              // onClick={() => openChallenge(item)}
                            >
                              {item.active ? (
                                <span style={{ color: "#20b839d1" }}>
                                  Active
                                </span>
                              ) : (
                                <span style={{ color: "f70000e3" }}>
                                  Deactive
                                </span>
                              )}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className="pr-0 text-left">
                        <button
                          className={`btn btn-light-primary
                        )} font-weight-bolder font-size-sm mx-1`}
                          onClick={() => openChallenge(item.id, item)}
                        >
                          View
                        </button>

                        <button
                          className="btn btn-light-info font-weight-bolder font-size-sm mx-1"
                          onClick={() =>
                            history.push("/edit-chalenge-rule", {
                              id: item.id,
                              data: item,
                            })
                          }
                        >
                          Edit
                        </button>
                       
                        {/* <button
                          className="btn btn-light-danger font-weight-bolder font-size-sm mx-1"
                          onClick={() => deleteRule(item.id)}
                        >
                          Delete
                        </button> */}
                        {/* <button
                        className="btn btn-light-warning font-weight-bolder font-size-sm mx-1"
                        // onClick={() => ActiveDeactiveActions(item)}
                      >
                        {item?.is_active === true ? "Deactivate" : "Activate"}
                      </button>  */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Rules;
