/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { login } from "../_redux/authCrud";
import { loginAdmin } from "../../../../redux/auth/action";
import { getErrorMessage } from "../../../../utils/functions";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [checkPass, setCheckPass] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .required("Please enter your email"),
    password: Yup.string()
      .min(3, "Password should contain minimum 3 letters")
      .max(50, "Maximum 50 words")
      .required("Please enter a valid password"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      if (fieldname === "password") {
        if (checkPass) {
          return "is-invalid";
        }
      }
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      enableLoading();
      let res = await login(values.email, values.password);
      if (res.status === 200) {
        let { data } = res;
        disableLoading();
        dispatch(loginAdmin(data));
        setCheckPass(false);
      } else {
        const message = getErrorMessage(res, "Failed to login");
        toast.dismiss();
        setCheckPass(true);
        console.log(formik.errors.password);
        toast.error(message, { theme: "colored" });
        disableLoading();
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {/* {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text ">
              Use account <strong>admin@demo.com</strong> and password
              <strong>demo</strong> to continue.
            </div>
          </div>
        )} */}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block ml-2">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            value={formik.values.password}
            onChange={(e) => {
              setCheckPass(false);
              formik.setFieldValue("password", e.target.value);
            }}
            onBlur={formik.handleBlur}
            // {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block ml-2">{formik.errors.password}</div>
            </div>
          ) : null}
          {!formik.errors.password && checkPass && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block ml-2 text-danger">
                Password didn't match{" "}
              </div>
            </div>
          )}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />?
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            {loading ? <div className="SpinnerStyle" /> : "Sign In"}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default Login;
