/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { updateAdminProfile } from "../Auth/_redux/authCrud";
import { loginAdmin } from "../../../redux/auth/action";
import { toast } from "react-toastify";

function AccountInformation(props) {
  // Fields
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  const history = useHistory();

  const initialValues = {
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
  
  const Schema = Yup.object().shape({
    firstname: Yup.string()
      .required("First name is required")
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/g,
        "First name can only contain alphabet."
      ),
    lastname: Yup.string().required("Last name is required").matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/g,
      "Last name can only contain alphabet."
    ),
    email: Yup.string()
      .email("Wrong email format")
      .required("Email is required"),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
      setloading(true);
      let sendValuesToDB = {
        first_name: values.firstname,
        last_name: values.lastname,
        email: values.email,
      };
      let res = await updateAdminProfile(sendValuesToDB);
      if (res.status === 200) {
        const { data } = res;
        const updateRedux = {
          ...data,
          token: user.token,
        };
        dispatch(loginAdmin(updateRedux));
        setloading(false);
        // history.push("/");
        toast.info(res.message, { theme: "colored" });
      } else {
        setloading(false);
        toast.error(res.message, { theme: "colored" });
      }
    },
  });

  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Account Information
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Change your account settings
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            Save Changes
            {formik.isSubmitting}
          </button>
          <Link
            to="/user-profile/profile-overview"
            className="btn btn-secondary"
          >
            Cancel
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        <div className="card-body">
          {/* begin::Heading */}
          <div className="row">
            <label className="col-xl-3"></label>
           
          </div>
          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              First Name
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "firstname"
                  )}`}
                  name="firstname"
                  {...formik.getFieldProps("firstname")}
                />
                {formik.touched.firstname && formik.errors.firstname ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.firstname}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Last Name
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "lastname"
                  )}`}
                  name="lastname"
                  {...formik.getFieldProps("lastname")}
                />
                {formik.touched.lastname && formik.errors.lastname ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.lastname}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* begin::Form Group */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Email Address
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-at"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Email"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <span className="form-text text-muted">
                Email will not be publicly displayed.{` `}
                <a href="#" className="font-weight-bold">
                  Learn more
                </a>
                .
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
export default connect(null)(AccountInformation);
