/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { Link, useHistory } from "react-router-dom";
import {
  challengeRulesListing,
  CreateChallenge,
} from "../Auth/_redux/authCrud";
import { toast } from "react-toastify";

import { ImageContainer } from "../../../GobalStyled";
import { useEffect } from "react";

export default function AddChallenge() {
  const [loading, setloading] = useState(false);
  const [rules, setRules] = useState([]);
  const [pic, setPic] = useState([]);

  const history = useHistory();

  const initialValues = {
    title: "",
    startDate: "",
    endDate: "",
    challengeAmount: "",
    hoursPerDay: 0,
    description: "",
    pic: [],
    category: "",
    assigned_task: "",
    days: "",
    rules: "",
  };
  const Schema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .min(5, "Title must contain atleast 5 charachers")
      .max(40, "Title can't be more than 40 charachers"),
    startDate: Yup.date()
      .required("Start Date is required")
      .min(
        new Date(Date.now()),
        `You can't select the prevoius date or today's date`
      ),
    //   new Date(Date.now()- 86400000),  used to select start date
    // eslint-disable-next-line no-use-before-define
    // let date = new Date(startDate); to reduce a day from the selected date
    // date.setDate(date.getDate() + 1);
    endDate: Yup.date().when("startDate", (startDate, schema) => {
      return (
        startDate &&
        schema
          .min(startDate, "You can't select the previous date then start date")
          .required("End Date is required")
      );
    }),
    category: Yup.string().required(),
    days: Yup.string()
      .min(1)
      .required(),
    challengeAmount: Yup.number()
      .required("Challenge Amount is required")
      .min(0, "Challenge amount must be greater than or equal to 0"),
    hoursPerDay: Yup.number().when("category", {
      is: "time",
      then: Yup.number()
        .required("Hours is a required Field")
        .min(1, "Hours can't be less than 1")
        .max(24, "Hours can't be greater than 24 hours"),
    }),
    assigned_task: Yup.string().when("category", {
      is: "task",
      then: Yup.string()
        .required("Assigned Task is a required Field")
        .min(10)
        .max(100),
    }),
    description: Yup.string().required("description is mandatory"),
    pic: Yup.array()
      .min(2, " Please add min 2")
      .max(5, "Max 5 images")
      .required(),
    // totalHours: Yup.number()
    //   .when("category", {
    //     is: "task",
    //     then: Yup.number().required("Please enter the number of hours"),
    //   })
    //   .when("days", (days, schema) => {
    //     let max = days * 24 - days * 14;
    //     return schema
    //       .min(1, `Total Hours must be greater than or equal to 1`)
    //       .max(max, `Total Hours must be less than or equal to ${max}`);
    //   }),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const Chal = (start, end) => {
    if (start && end) {
      let starting = new Date(start);
      //we need  to reduce a day from starting date to get exact number of days
      starting.setDate(starting.getDate() - 1);

      let difference = new Date(end).getTime() - starting.getTime();

      let TotalDays = Math.ceil(difference / (24 * 60 * 60 * 1000));

      return TotalDays;
    }
    return "";
  };

  // console.log(Chal("10-05-2022", "10-15-2022"), "@chal");
  // const hours = () => {
  //   if (formik.values.category === "task") {
  //     return formik.values.assigned_task;
  //   } else {
  //     return formik.values.hoursPerDay;
  //   }
  // };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
      let formData = new FormData();
      // console.log(values, "valuers----------------");
      setloading(true);

      formData.append("title", values.title);
      formData.append("start_date", values.startDate);
      formData.append("end_date", values.endDate);
      formData.append("challenge_amount", values.challengeAmount);
      formData.append("no_of_hours", values.hoursPerDay);
      formData.append("description", values.description);
      formData.append("rule_id", values.rules);
      formData.append("category", values.category);
      // eslint-disable-next-line array-callback-return
      values.pic.map((val) => {
        formData.append("image_name", val);
      });
      if (values.category === "task") {
        formData.append("assigned_task", values.assigned_task);
      }

      //execute the create-challenge-api
      let res = await CreateChallenge(formData);
      if (res.status === 200) {
        setloading(false);
        history.push("/challenges");
        toast.success(res.message, { theme: "colored" });
      } else {
        setloading(false);
        toast.error(res.message, { theme: "colored" });
      }
    },
  });

  const handleImages = (event) => {
    if (pic.length === 0) {
      const files = event.target.files;
      let myFiles = Array.from(files);
      if (myFiles.length <= 5) {
        setPic(myFiles);
        formik.setFieldValue("pic", myFiles);
      }
    } else {
      if (pic.length < 5) {
        const files = event.target.files;
        let update = pic.concat(Array.from(files));
        if (update.length <= 5) {
          setPic(update);
          formik.setFieldValue("pic", update);
        }
      }
    }
    // console.log(formik.values);
  };

  const removeImg = (ind) => {
    let update = [];
    // eslint-disable-next-line array-callback-return
    pic.map((val, id) => {
      if (ind !== id) {
        update.push(val);
      }
    });
    setPic(update);
    formik.setFieldValue("pic", update);
  };

  const getRules = async () => {
    let res = await challengeRulesListing();
    if (res.status === 200) {
      setRules(res.data);
      formik.setFieldValue("rules", res.data[0].id);
    }
  };
  useEffect(() => {
    getRules();
    // console.log(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRules = (name) => {
    // eslint-disable-next-line array-callback-return
    rules.map((value) => {
      if (value.title === name) {
        formik.setFieldValue("rules", value.id);
      }
    });
  };

  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Create a New Challenge
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            “ Challenges are what make life interesting. Overcoming them is what
            makes life meaningful. ”
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            Save Changes
            {formik.isSubmitting}
          </button>
          <Link to="/challenges" className="btn btn-secondary">
            Cancel
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        <div className="card-body">
          {/* begin::Heading */}
          <div className="row">
            <label className="col-xl-3"></label>
          </div>
          {/* begin::Form Group */}

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Images</label>
            <div className="col-lg-9 col-xl-6">
              <label
                className="btn  btn-xs btn-icon  btn-white    btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Addpic"
              >
                <i className="fa fa-clone icon-lg  mx-1 "></i>
                <input
                  name="pic"
                  type="file"
                  className={`border-0 ${getInputClasses("pic")}`}
                  accept="image/*"
                  multiple
                  onChange={(event) => {
                    handleImages(event);
                  }}
                  onBlur={(e) => formik.handleBlur(e)}
                />
                <input type="hidden" name="pic" />
              </label>
              <span className="text-muted pl-5">
                {" "}
                Add min 2 and max 5 images{" "}
              </span>
              {formik.touched.pic && formik.errors.pic ? (
                <div className="text-danger">{formik.errors.pic}</div>
              ) : null}
              <ImageContainer>
                {pic &&
                  pic?.map((value, id) => (
                    <div>
                      <div className="mx-1 rounded my-2">
                        <img src={URL.createObjectURL(value)} />
                        <span onClick={() => removeImg(id)}>✘</span>
                      </div>
                    </div>
                  ))}
              </ImageContainer>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Title</label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "title"
                  )}`}
                  name="title"
                  {...formik.getFieldProps("title")}
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.title}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Start Date
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="date"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "startDate"
                  )}`}
                  name="startDate"
                  {...formik.getFieldProps("startDate")}
                />
                {formik.touched.startDate && formik.errors.startDate ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.startDate}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {formik.values.startDate && (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                End Date
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="date"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "endDate"
                    )}`}
                    // min='2022-07-20'
                    // max='2022-07-28'
                    name="endDate"
                    {...formik.getFieldProps("endDate")}
                  />

                  {formik.touched.endDate && formik.errors.endDate ? (
                    <div className="invalid-feedback ml-2">
                      {formik.errors.endDate}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Challenge Amount
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="number"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "challengeAmount"
                  )}`}
                  name="challengeAmount"
                  {...formik.getFieldProps("challengeAmount")}
                />

                {formik.touched.challengeAmount &&
                formik.errors.challengeAmount ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.challengeAmount}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* Category and duration input */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Category</label>
            <div className="col-lg-9 col-xl-6  d-flex">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="time"
                  name="category"
                  value="time"
                  onChange={(e) => {
                    formik.setFieldValue("category", e.target.value);
                    console.log(formik.values, "values");
                    console.log(formik.errors, "errors");
                  }}
                  onBlur={(e) => formik.handleBlur(e)}
                />
                <label class="custom-control-label" for="time">
                  Time Based
                </label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input "
                  id="task"
                  name="category"
                  value="task"
                  onChange={(e) => {
                    formik.setFieldValue("category", e.target.value);
                    console.log(formik.values, "values");
                    console.log(formik.errors, "errors");
                  }}
                  onBlur={(e) => formik.handleBlur(e)}
                />
                <label class="custom-control-label" for="task">
                  Task Based
                </label>
              </div>

              {formik.touched.category && formik.errors.category ? (
                <div className="invalid-feedback ml-2">
                  {formik.errors.category}
                </div>
              ) : null}
            </div>
          </div>

          {/* Category and duration input end */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Challenge Period
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "days"
                  )}`}
                  readOnly
                  // value={Chal(formik.values.startDate, formik.values.endDate)}
                  name="days"
                  {...(formik.values.days = Chal(
                    formik.values.startDate,
                    formik.values.endDate
                  ))}
                  {...formik.getFieldProps("days")}
                />

                {formik.touched.days && formik.errors.days ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.days}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {rules.length > 0 && (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Challenge Rules
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <select
                    name="rules"
                    className={`form-control form-control-lg form-control-solid `}
                    onChange={(e) => handleRules(e.target.value)}
                    // onBlur={(e) => formik.handleBlur(e)}
                  >
                    {rules?.map((item) => (
                      <option> {item.title}</option>
                    ))}
                  </select>
                  {formik.touched.rules && formik.errors.rules ? (
                    <div className="invalid-feedback ml-2">
                      {formik.errors.rules}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}

          {formik.values.category === "time" && (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Hours per day
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="number"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "hoursPerDay"
                    )}`}
                    name="hoursPerDay"
                    {...formik.getFieldProps("hoursPerDay")}
                  />
                  {formik.touched.hoursPerDay && formik.errors.hoursPerDay ? (
                    <div className="invalid-feedback ml-2">
                      {formik.errors.hoursPerDay}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          {formik.values.category === "task" && (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Assigned task
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "assigned_task"
                    )}`}
                    name="assigned_task"
                    {...formik.getFieldProps("assigned_task")}
                  />
                  {formik.touched.assigned_task &&
                  formik.errors.assigned_task ? (
                    <div className="invalid-feedback ml-2">
                      {formik.errors.assigned_task}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Description
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <textarea
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "description"
                  )}`}
                  name="description"
                  {...formik.getFieldProps("description")}
                />
                {formik.touched.description && formik.errors.description ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.description}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Deposit Amount
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="number"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "depositeAmount"
                  )}`}
                  name="depositeAmount"
                  {...formik.getFieldProps("depositeAmount")}
                />

                {formik.touched.depositeAmount &&
                formik.errors.depositeAmount ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.depositeAmount}
                  </div>
                ) : null}
              </div>
            </div>
          </div> */}

          {/* <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Days per week
            </label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="number"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "daysPerWeek"
                  )}`}
                  name="daysPerWeek"
                  {...formik.getFieldProps("daysPerWeek")}
                />
                {formik.touched.daysPerWeek && formik.errors.daysPerWeek ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.daysPerWeek}
                  </div>
                ) : null}
              </div>
            </div>
          </div> */}
          {/* {formik.values.challengeDuration && (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                No. of Days
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="number"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "hoursPerDay"
                    )}`}
                    name="hoursPerDay"
                    {...formik.getFieldProps("hoursPerDay")}
                  />
                  {formik.touched.hoursPerDay && formik.errors.hoursPerDay ? (
                    <div className="invalid-feedback ml-2">
                      {formik.errors.hoursPerDay}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )} */}
          {/* <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label">
            Email Address
          </label>
          <div className="col-lg-9 col-xl-6">
            <div className="input-group input-group-lg input-group-solid">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fa fa-at"></i>
                </span>
              </div>
              <input
                type="text"
                placeholder="Email"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="invalid-feedback">{formik.errors.email}</div>
              ) : null}
            </div>
            <span className="form-text text-muted">
              Email will not be publicly displayed.{` `}
              <a href="#" className="font-weight-bold">
                Learn more
              </a>
              .
            </span>
          </div>
        </div> */}
          {/* {formik.values.category && (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Challenge Duration
              </label>
              <div className="col-lg-9 col-xl-6">
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="days"
                    name="challengeDuration"
                    value="days"
                    onChange={(e) =>
                      formik.setFieldValue("challengeDuration", e.target.value)
                    }
                    onBlur={(e) => formik.handleBlur(e)}
                  />
                  <label class="custom-control-label" for="days">
                    days
                  </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input success"
                    id="weeks"
                    name="challengeDuration"
                    value="weeks"
                    onChange={(e) =>
                      formik.setFieldValue("challengeDuration", e.target.value)
                    }
                    onBlur={(e) => formik.handleBlur(e)}
                  />
                  <label class="custom-control-label" for="weeks">
                    Weeks
                  </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="months"
                    name="challengeDuration"
                    value="months"
                    onChange={(e) =>
                      formik.setFieldValue("challengeDuration", e.target.value)
                    }
                    onBlur={(e) => formik.handleBlur(e)}
                  />
                  <label class="custom-control-label" for="months">
                    Months
                  </label>
                </div>
                {formik.touched.challengeDuration &&
                formik.errors.challengeDuration ? (
                  <div className="invalid-feedback ml-2">
                    {formik.errors.challengeDuration}
                  </div>
                ) : null}
              </div>
            </div>
          )} */}
        </div>
      </div>
    </form>
  );
}
