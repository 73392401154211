import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  InfoLabel,
  InfoLine,
  InfoValue,
  InfoWrapper,
} from "../../../../GobalStyled";
const ReportInfo = (props) => {
  const [report, setReport] = useState([]);
  const [reason, setReason] = useState("");
  useEffect(() => {
    setReason(props.data.reason);
    setReport(props.data.report);
  }, [props]);
  return (
    <InfoWrapper>
      <div>
        <InfoLine>
          <InfoLabel>Evidence</InfoLabel>
          <InfoLabel>Reason</InfoLabel>
        </InfoLine>
        <InfoLine>
          <InfoValue>
            <div
              style={{
                backgroundImage: `url(${report?.evidence})`,
                borderRadius: "20px",
                backgroundSize: "100% 100%",
                height: "200px",
                width: "200px",
              }}
            ></div>
          </InfoValue>
          <InfoValue>{reason}</InfoValue>
        </InfoLine>
      </div>
    </InfoWrapper>
  );
};
export default ReportInfo;
