import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  InfoLabel,
  InfoLine,
  InfoValue,
  InfoWrapper,
} from "../../../GobalStyled";

const style = {
  position: "absolute",
  right: "-20px",
  top: "-20px",
  width: "50px",
  height: "50px",
  background: "red",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#ffffff",
  fontSize: "28px",
  zIndex: "1",
  cursor: "pointer",
};

export default function ViewChallengeRules({ open, close, id, ruleData }) {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState([]);

  // const [data, setData] = useState({});
  const getChallengeDetails = async () => {
    setLoading(true);
    if (ruleData) {
      setLoading(false);
    }
    // let res = await ViewChallenge(id);
    // if (res.status === 200) {
    //   setLoading(false);
    //   setData(res.data);
    // } else {
    //   toast.error(res.message, { theme: "colored" });
    //   setLoading(false);
    //   close();
    // }
  };
  const rulelist = () => {
    const data = ruleData?.rules.split("\n");
    setText(data);
    console.log(data);
  };

  useEffect(() => {
    getChallengeDetails();
    rulelist();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      show={open}
      onHide={close}
      dialogClassName="modal-120w"
      size="md"
      centered
      style={{ background: "rgba(0,0,0,0.6)" }}
    >
      <span style={style} onClick={close}>
        &#10008;
      </span>
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          View Rule
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="LoaderWrapper">
            <div className="BackgroundLoader" />
          </div>
        ) : (
          <InfoWrapper>
            <div>
              <InfoLine>
                <InfoLabel>Title</InfoLabel>
                <InfoValue>{ruleData.title}</InfoValue>
              </InfoLine>

              <InfoLine>
                <InfoLabel>Rules</InfoLabel>
              </InfoLine>
              {text !== []
                ? text.map((txt) => {
                    return (
                      <InfoLine>
                        <span>{txt}</span>
                      </InfoLine>
                    );
                  })
                : ""}
            </div>
          </InfoWrapper>
        )}
      </Modal.Body>
    </Modal>
  );
}
