import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link,  useHistory } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { forgotpassword } from "../_redux/authCrud";
import { getErrorMessage } from "../../../../utils/functions";
import { toast } from 'react-toastify';

const initialValues = {
  email: "",
};

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const history = useHistory()
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Please enter your registered email id"),
  });


  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    // if(formik.errors[fieldname]){
    //   setIsError('')
    // }

    return "";
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values) => {
      enableLoading();

      const res = await forgotpassword(values.email);
      if (res.status === 200) {
        history.push('/auth')
        toast.info(res.message , {theme:"colored"})
        disableLoading();
      } else {
        const message = getErrorMessage(res, "Failed to forgotten password");
        toast.error(message , {theme:"colored"})
        disableLoading();
      }
    },
  });

  return (
    <>
      { (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Forgot Password?</h3>
            <div className="text-muted font-weight-bold">
              Enter your email to reset your password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {/* {isError && !formik.errors.email  &&  (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {isError}
                </div>
              </div>
            )} */}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block ml-2">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                // disabled={formik.isSubmitting}
              >
            {loading ? <div  className="SpinnerStyle" /> : 'Submit'}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
