import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
  ActionButtonForUser,
  DetailsRow,
  PageInnerContainer,
  UserFollowingDetails,
  UserInfoBox,
  UserProfileInfo,
  UserProfileInfoInner,
} from "../../../GobalStyled";
import { getErrorMessage } from "../../../utils/functions";
import {
  blockUnblockUser,
  userDelete,
  userDetails,
} from "../Auth/_redux/authCrud";
import DeleteBlock from "../Modals/DeleteBlock";
import UserChallenge from "./UserChallenge";
import UserInfo from "./UserInfo";

export default function ViewProfile(props) {
  const [activeTab, setActiveTab] = useState("info");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [parameter, setParameter] = useState({});
  const [modalLoder, setModalLoder] = useState(false);
  const history = useHistory();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getDetails = async () => {
    enableLoading();
    const res = await userDetails(props.location.state.id);
    if (res.status === 200) {
      setUserData(res.data);
      disableLoading();
    } else {
      const message = getErrorMessage(res, "Failed to forgotten password");
      toast.error(message, { theme: "colored" });
      disableLoading();
    }
  };

  const blockUser = (data) => {
    setOpenModal(true);
    if (data?.blocked === true) {
      let para = {
        name: "Unblock",
        body: "this user",
        id: data.id,
        status: false,
      };
      setParameter(para);
    }
    if (!data?.blocked === true) {
      let para = {
        name: "Block",
        body: "this user",
        id: data.id,
        status: true,
      };
      setParameter(para);
    }
  };

  const deleteUser = (id) => {
    setOpenModal(true);
    let para = {
      name: "Delete",
      body: "this user",
      id: id,
    };
    setParameter(para);
  };

  // const getLocation = () => {
  //   let href="https://nominatim.openstreetmap.org/reverse?lat=30.7130097&lon=76.7092797&format=json"
  // }

  const deleteAction = async () => {
    setModalLoder(true);
    if (parameter.name === "Delete") {
      let res = await userDelete(parameter.id);
      if (res.status === 200) {
        setModalLoder(false);
        setOpenModal(false);
        history.push("/user-profile/profile-overview");
        toast.info(res.message, { theme: "colored" });
      } else {
        setModalLoder(false);
        setOpenModal(false);
        toast.error(res.message, { theme: "colored" });
      }
    }
    if (parameter.name === "Block" || parameter.name === "Unblock") {
      let req = {
        user_id: parameter.id,
        status: parameter.status,
      };
      let res = await blockUnblockUser(req);
      if (res.status === 200) {
        setModalLoder(false);
        setOpenModal(false);
        getDetails();
        toast.info(res.message, { theme: "colored" });
      } else {
        setModalLoder(false);
        setOpenModal(false);
        toast.error(res.message, { theme: "colored" });
      }
    }
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }

  return (
    <>
      {openModal && (
        <DeleteBlock
          open={openModal}
          close={() => setOpenModal(false)}
          confirmSumbit={deleteAction}
          parameter={parameter}
          loading={modalLoder}
        />
      )}
      {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}

      {userData ? (
        <PageInnerContainer>
          <UserInfoBox>
            <UserProfileInfo>
              {userData.profile_pic ? (
                <img src={userData.profile_pic} alt="Profile" />
              ) : userData.gender === "male" ? (
                <img src="../media/svg/avatars/001-boy.svg" alt="Profile" />
              ) : (
                <img src="../media/svg/avatars/002-girl.svg" alt="Profile" />
              )}
              <div>
                <h4>{userData?.first_name + " " + userData?.last_name}</h4>
                <span>@{userData?.user_name}</span>
                <UserProfileInfoInner>
                  <p>Student</p>
                  <label>
                    {userData?.active === true ? "Active" : "Inactive"}
                  </label>
                </UserProfileInfoInner>
              </div>
            </UserProfileInfo>
            <UserFollowingDetails>
              <div>
                <p>{userData?.userChallenges?.length}</p>
                <span>Number of active Challenges</span>
              </div>
            </UserFollowingDetails>

            <ActionButtonForUser>
              <span className="Block" onClick={() => blockUser(userData)}>
                {userData?.blocked === true ? "Unblock User" : "Block User"}
              </span>

              <span className="Delete" onClick={() => deleteUser(userData.id)}>
                {userData?.deleted === true ? "User not found" : "Delete"}
              </span>
            </ActionButtonForUser>
          </UserInfoBox>
          <DetailsRow tab={activeTab === "info" ? 1 : 2}>
            <div>
              <span
                className={activeTab === "info" && "active"}
                onClick={() => setActiveTab("info")}
              >
                Info
              </span>
            </div>
            <div>
              <span
                className={activeTab === "video" && "active"}
                onClick={() => setActiveTab("video")}
              >
                Challenges
              </span>
            </div>
          </DetailsRow>
          {activeTab === "info" && <UserInfo data={userData} />}
          {activeTab === "video" && (
            <UserChallenge data={userData?.userChallenges} />
          )}
        </PageInnerContainer>
      ) : (
        <span className="ml-3 spinner spinner-white"></span>
      )}
    </>
  );
}
